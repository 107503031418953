@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;600&display=swap');

/* Styles */
.body {
  text-align: center;
  font-family: 'Rubik', sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* inverted */
  /* background-color: black; */
  background-color: white;
  background-image: linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0.3)), url(./assets/perfect_circle_3.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.title, .about-title {
  padding-top: 100px;
  padding-bottom: 100px;
  font-weight: 600;
  font-size: 32px;
  color: white;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.about-title {
  padding: 25px 0;
}

.qrcode {
  width: 200px;
}

.about-text {
  padding: 7px;
  max-width: 400px;
  background-color:rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: white;
}

.pub-key {
  vertical-align: auto;
  margin: 0 auto;
  max-width: 350px;
  overflow: auto;
  font-size: 14px;
  background-color: black;
  overflow-wrap: break-word;
  border-radius: 4px;
}

a {
  color: white;
}

.button {
  width: 150px;
  padding: 10px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  /* inverted */
  /* background-color: rgba(255, 255, 255, 0.7); */
}

.buttons {
  animation-name: floatUp;
  animation-duration: 1s;
}

.footer {
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  /* inverted */
  /* background-color: rgba(255, 255, 255, 0.7); */
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1.5% 0;
  z-index: 10;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes floatUp {
  from {
    opacity: 0;
    transform: translate(0px, 100px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
